{
    "private": true,
    "name": "crop-performance",
    "version": "0.0.0",
    "engines": {
        "node": "^22",
        "npm": "^10"
    },
    "scripts": {
        "ng": "ng",
        "prestart": "npm run localize",
        "start": "ng serve --open --watch --proxy-config proxy.conf.json",
        "start:mock-server": "ts-node mock-server/server",
        "start:bff": "dotnet run --project ../BffCloudServer/",
        "localize": "localization-cli -s ./localization/source -d ./src/assets/translations",
        "localize:debug": "localization-cli --verbose -s ./localization/source -d ./src/assets/translations",
        "test": "jest --coverage --maxWorkers=50% --config=jest.config.js",
        "test:watch": "jest --coverage --maxWorkers=25% --watch",
        "build": "npm run localize && ng build",
        "build:tst": "npm run localize && ng build --configuration test",
        "build:acc": "npm run localize && ng build --configuration acceptance",
        "build:prod": "npm run localize && ng build --configuration production",
        "build:source-maps": "npm run localize && ng build --configuration=production --source-map",
        "lint": "npm run lint:check",
        "lint:check": "run-p lint:check:*",
        "lint:check:scripts": "cross-env NODE_OPTIONS=--max-old-space-size=8192 eslint . --cache",
        "lint:check:styles": "stylelint **/*.{css,scss} --cache",
        "lint:check:format": "prettier --check . --ignore-unknown --log-level warn",
        "lint:fix": "run-p lint:fix:*",
        "lint:fix:scripts": "cross-env NODE_OPTIONS=--max-old-space-size=8192 eslint . --fix --cache",
        "lint:fix:styles": "stylelint --fix **/*.{css,scss} --cache",
        "lint:fix:format": "prettier --write . --ignore-unknown --log-level warn",
        "analyze-bundle-size": "npm run build:source-maps && source-map-explorer dist/*.js"
    },
    "prettier": "@priva/linter/prettier-config",
    "overrides": {
        "@stdlib/number-float64-base-normalize@0.0.7": "0.0.8",
        "postcss": "$postcss"
    },
    "dependencies": {
        "@angular-architects/ngrx-toolkit": "^18.1.1",
        "@angular/animations": "18.2.8",
        "@angular/cdk": "18.2.8",
        "@angular/common": "18.2.8",
        "@angular/compiler": "18.2.8",
        "@angular/core": "18.2.8",
        "@angular/forms": "18.2.8",
        "@angular/platform-browser": "18.2.8",
        "@angular/platform-browser-dynamic": "18.2.8",
        "@angular/router": "18.2.8",
        "@ngrx/effects": "18.1.0",
        "@ngrx/operators": "18.1.0",
        "@ngrx/router-store": "18.1.0",
        "@ngrx/signals": "18.1.1",
        "@ngrx/store": "18.1.0",
        "@ngx-translate/core": "^15.0.0",
        "@popperjs/core": "^2.11.8",
        "@priva/analytics": "^13.0.2",
        "@priva/appshell": "^27.4.3",
        "@priva/auth": "^14.1.0",
        "@priva/components": "^49.6.1",
        "@priva/dataviz": "^0.14.0",
        "@priva/error-pages": "^16.1.0",
        "@priva/localization": "^15.4.2",
        "@priva/styles": "^32.9.0",
        "@priva/utilities": "^13.6.1",
        "@priva/variables": "^0.3",
        "@segment/analytics-next": "^1.75.0",
        "angular-oauth2-oidc": "^17.0.2",
        "dayjs": "1.11.9",
        "focus-visible": "^5.2.1",
        "highcharts": "^11.4.8",
        "highcharts-angular": "^4.0.1",
        "lodash-es": "4.17.21",
        "ng2-date-picker": "~18.0.0",
        "ngx-window-token": "^7.0.0",
        "rxjs": "~7.8.1",
        "tslib": "^2.7.0",
        "ws": "^8.18.0",
        "zone.js": "~0.14.10"
    },
    "devDependencies": {
        "@angular-devkit/build-angular": "18.2.8",
        "@angular-devkit/core": "18.2.8",
        "@angular-devkit/schematics": "18.2.8",
        "@angular/cli": "18.2.8",
        "@angular/compiler-cli": "18.2.8",
        "@angular/language-service": "18.2.8",
        "@ngrx/schematics": "18.1.0",
        "@ngrx/store-devtools": "18.1.0",
        "@playwright/test": "1.47.1",
        "@priva/linter": "^9.3.0",
        "@priva/localization-cli": "^5.2.1",
        "@priva/mock-server": "^1.3.1",
        "@types/jest": "29.5.13",
        "@types/lodash": "4.17.7",
        "@types/node": "^22",
        "@typescript-eslint/eslint-plugin": "7.18.0",
        "@typescript-eslint/parser": "7.18.0",
        "@typescript-eslint/utils": "7.18.0",
        "class-transformer": "0.5.1",
        "class-validator": "0.14.1",
        "cross-env": "7.0.3",
        "eslint": "8.57.1",
        "eslint-config-prettier": "9.1.0",
        "eslint-plugin-cypress": "3.5.0",
        "eslint-plugin-html": "8.1.1",
        "eslint-plugin-node": "11.1.0",
        "eslint-plugin-storybook": "~0.8.0",
        "eslint-plugin-unused-imports": "^3.2.0",
        "husky": "9.1.6",
        "jest": "29.7.0",
        "jest-preset-angular": "14.2.4",
        "lint-staged": "15.2.10",
        "ng-mocks": "14.13.1",
        "npm-run-all": "4.1.5",
        "postcss": "8.4.47",
        "postcss-scss": "4.0.9",
        "prettier": "3.3.3",
        "source-map-explorer": "2.5.3",
        "stylelint": "16.9.0",
        "ts-jest": "29.2.5",
        "ts-node": "~10.9.2",
        "tsconfig-paths": "^4.2.0",
        "typescript": "5.5.4",
        "uuid": "10.0.0"
    }
}
